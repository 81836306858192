@import "../config";

@font-face {
  font-family: "Noto Sans Mono";
  font-weight: 500;
  src:
    local("Noto Sans Mono"),
    local("NotoSansMono-Medium"),
    url("#{$mri-noto-sans-mono-default-path}NotoSansMono-Medium.ttf") format("truetype"),
    url("#{$mri-noto-sans-mono-cdn-path}NotoSansMono-Medium.ttf") format("truetype");
}