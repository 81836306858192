@import "../config";

@font-face {
  font-family: "Noto Sans Mono";
  font-weight: 200;
  src:
    local("Noto Sans Mono"),
    local("NotoSansMono-ExtraLight"),
    url("#{$mri-noto-sans-mono-default-path}NotoSansMono-ExtraLight.ttf") format("truetype"),
    url("#{$mri-noto-sans-mono-cdn-path}NotoSansMono-ExtraLight.ttf") format("truetype");
}