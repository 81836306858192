@import "../config";

@font-face {
  font-family: "Noto Sans Mono";
  font-weight: 100;
  src:
    local("Noto Sans Mono"),
    local("NotoSansMono-Thin"),
    url("#{$mri-noto-sans-mono-default-path}NotoSansMono-Thin.ttf") format("truetype"),
    url("#{$mri-noto-sans-mono-cdn-path}NotoSansMono-Thin.ttf") format("truetype");
}